<template>
  <v-card elevation="0">
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          v-for="(filter, index) in filters"
          :key="index"
          class="px-0"
        >
          <!--             :class="[index > 0 ? 'mt-4' : '']"
 -->
          <!-- combobox text and value -->
          <v-combobox
            v-if="filter.type == 'comboTextVal'"
            hide-details="auto"
            v-model="selected[filter.name]"
            :items="filter.items"
            :label="$t(filter.name)"
            clearable
            :item-text="filter.itemText"
            :item-value="filter.itemValue"
            @change="filterChange(pagesItems)"
          >
          </v-combobox>
          <!-- combobox -->

          <v-combobox
            v-if="filter.type == 'combo'"
            hide-details="auto"
            v-model="selected[filter.name]"
            :items="filter.items"
            :label="$t(filter.name)"
            clearable
            @change="filterChange(pagesItems)"
          >
          </v-combobox>
          <!-- combobox multi for more then one selected item-->

          <v-combobox
            v-if="filter.type == 'comboMulti'"
            hide-details="auto"
            v-model="selected[filter.name]"
            :items="filter.items"
            :label="$t(filter.name)"
            :item-text="filter.itemText"
            :item-value="filter.itemValue"
            @change="filterChange(pagesItems)"
            clearable
            multiple
            chips
          >
          </v-combobox>
          <!-- date picker-->

          <date-picker
            v-if="filter.type == 'date'"
            :name="filter.name"
            :lableName="filter.lableName"
            :dateOptions="{
              dateClearable: filter.Clearable,
              dateDisabled: filter.ReadOnly,
              dataFieldValidation: filter.FieldValidation,
              dateRules: filter.FieldValidationFuntion,
            }"
            :dateValue="null"
            @clearDate="selected[filter.name] = null"
            @changeDatePicker="selected[filter.name] = $event"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  //filterItems is a array of items with name(string),type(string) and items(array). pagesItems is for paging your datatable
  props: ["pagesItems", "filterItems"],
  data() {
    return {
      selected: {},
    };
  },
  computed: {
    filters() {
      //filteritems need a name(string),type(string) and items(array). Depending on the item you need you can add itemText(string) and itemValue(string) with the type comboTextVal
      /*
          name: "Status",
          type: "comboTextVal",
          items: [
            { Name: "azer1", Id: 1 },
            { Name: "azer2", Id: 2 },
            { Name: "azer3", Id: 3 },
            { Name: "azer4", Id: 12 },
            { Name: "azer5", Id: 123 },
          ],
          itemText: "Name",
          itemValue: "Id",
      */

      this.filterItems.forEach((element) => {
        if (element["value"]) {
          this.selected[element.name] = element.value;
        }
      });
      return this.filterItems;
    },
  },
  methods: {
    clearDate(name) {
      this.selected[name] = null;
      this.filterChange(this.pagesItems);
    },
    filterChange(pageItems) {
      //emits data back to parent and can be catched by @filterChange in the component
      this.$emit("filterChange", { items: this.selected, pageing: pageItems });
    },
  },
};
</script>

<style scoped>
</style>