import Vue from "vue"
//source and testing --> https://regexr.com/
export default {
    validations() {
        return {
            notEmptyStringRule: [
                (v) => !!v || Vue.i18n.translate("Field is required")
            ],
            notEmptyEmailRule: [
                (v) => !!v || Vue.i18n.translate("E-mail is required"),
                (v) => (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(v) || Vue.i18n.translate("E-mail must be valid"),// eslint-disable-line
            ],
            emptyOrEmailRule: [
                (v) => (/^$|[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(v) || Vue.i18n.translate("E-mail must be valid"),// eslint-disable-line
            ],
            notEmptyNumberRule: [
                (v) => !!v || Vue.i18n.translate("Field is required"),
                (v) => /[0-9]$/.test(v) || Vue.i18n.translate("Not a number"),
            ],
            emptyOrNumbersOnlyRule: [
                (v) => /^$|[0-9]$/.test(v) || Vue.i18n.translate("Not a number"),
            ],
            notEmptyUpperLowerAlfabeticRule: [
                (v) => !!v || Vue.i18n.translate("Field is required"),
                (v) => (/[A-Za-z]$/.test(v) && !!v) || Vue.i18n.translate("Needs to be letters only"),
            ],
            emptyOrUpperLowerAlfabeticRule: [
                (v) => (/^$|[A-Za-z]$/.test(v) && !!v) || Vue.i18n.translate("Needs to be letters only"),
            ],
            notEmptyUpperLowerAlfabeticNumericRule: [
                (v) => !!v || Vue.i18n.translate("Field is required"),
                (v) => (/[A-Za-z0-9]$/.test(v) && !!v) || Vue.i18n.translate("Must be valid"),
            ],
            emptyOrUpperLowerAlfabeticNumericRule: [
                (v) => (/^$|[A-Za-z0-9]$/.test(v) && !!v) || Vue.i18n.translate("Must be valid"),
            ],
            notEmptyInternationalTelRule: [
                (v) => !!v || Vue.i18n.translate("Phone is required"),
                (v) =>
                    /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
                        v
                    ) || Vue.i18n.translate("Phone must be valid"),
            ],
            emptyOrInternationalTelRule: [
                (v) =>
                    /^$|(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
                        v
                    ) || Vue.i18n.translate("Phone must be valid"),
            ],
            VATcheck: [
                (v) => /^(0[0-9]{9})$/.test(v) || Vue.i18n.translate("No valid 'Belgian' VAT number"),
            ],


        }
    },
    getAllValidationNames() {
        const allValidations = this.validations()
        let names = []

        for (const key in allValidations) {
            names.push(key)
        }
        return names
    },
    getValidation(Name) {

        return this.validations()[Name]
    },



}
//https://stackoverflow.com/questions/5063977/regex-empty-string-or-email --> empty regex
/* (?xi)^(
(AT)?U[0-9]{8} |                              # Austria
(BE)?0[0-9]{9} |                              # Belgium
(BG)?[0-9]{9,10} |                            # Bulgaria
(HR)?[0-9]{11} |                              # Croatia
(CY)?[0-9]{8}[A-Z] |                          # Cyprus
(CZ)?[0-9]{8,10} |                            # Czech Republic
(DE)?[0-9]{9} |                               # Germany
(DK)?[0-9]{8} |                               # Denmark
(EE)?[0-9]{9} |                               # Estonia
(EL)?[0-9]{9} |                               # Greece
ES[A-Z][0-9]{7}(?:[0-9]|[A-Z]) |              # Spain
(FI)?[0-9]{8} |                               # Finland
(FR)?[0-9A-Z]{2}[0-9]{9} |                    # France
(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3}) | # United Kingdom
(HU)?[0-9]{8} |                               # Hungary
(IE)?[0-9]{7}[A-Z]{1,2}   |                   # Ireland
(IE)?[0-9][A-Z][0-9]{5}[A-Z] |                # Ireland (2)
(IT)?[0-9]{11} |                              # Italy
(LT)?([0-9]{9}|[0-9]{12}) |                   # Lithuania
(LU)?[0-9]{8} |                               # Luxembourg
(LV)?[0-9]{11} |                              # Latvia
(MT)?[0-9]{8} |                               # Malta
(NL)?[0-9]{9}B[0-9]{2} |                      # Netherlands
(PL)?[0-9]{10} |                              # Poland
(PT)?[0-9]{9} |                               # Portugal
(RO)?[0-9]{2,10} |                            # Romania
(SE)?[0-9]{12} |                              # Sweden
(SI)?[0-9]{8} |                               # Slovenia
(SK)?[0-9]{10}                                # Slovakia
)$ */