import auth from './auth'
import Vue from 'vue'

export default {
  getDisplayLabel: function (item, DisplayLabelCustomFormat = false) {
    let displayLabel = ""
    let userLanguage = auth.userLanguage()
    userLanguage = userLanguage.charAt(0).toUpperCase() + userLanguage.slice(1);
    if (DisplayLabelCustomFormat) {
      //split on first part of the item and returns array of strings
      let DisplayLabelCustomFormatArray = DisplayLabelCustomFormat.split("}");
      //split on second part of the every item in the array
      let seachableArray = []
      DisplayLabelCustomFormatArray.forEach((element) => {
        seachableArray.push(...element.split("{"))
      });
      //filter the array for empty string
      seachableArray = seachableArray.filter(e => e)
      //Loops through all formed keys and searches for a match
      //', '--> needs this for substring
      seachableArray.forEach(el => {
        let extentionItem = " "
        if (item.Data[el]) {
          if (seachableArray[seachableArray.indexOf(el) + 1] != null || seachableArray[seachableArray.indexOf(el) + 1] != undefined) {
            extentionItem = seachableArray[seachableArray.indexOf(el) + 1]
          }
          if (item.Data[el].Type == "text" || item.Data[el].Type == "richtext") {
            displayLabel += this.isTypeTextOrRichText(item.Data[el], userLanguage) + extentionItem
          }
          else if (item.Data[el].Type == "number") {
            displayLabel += this.isTypeNumber(item.Data[el]) + extentionItem
          }
          else if (item.Data[el].Type == "dropdown") {
            displayLabel += item.Data[el].ValueSort + extentionItem
          }
          else if (item.Data[el].Type == "boolean") {
            displayLabel += item.Data[el].Value ? Vue.i18n.translate("Yes") + extentionItem
              : Vue.i18n.translate("No") + extentionItem
          }
          else if (item.Data[el].Type == "files" && item.Data[el].Value.length > 0) {
            item.Data[el].Value.forEach(e => displayLabel += e + extentionItem);
          }
          else if (item.Data[el].Type == "gallery" && item.Data[el].Value.length > 0) {
            item.Data[el].Value.forEach(e => displayLabel += e.Name + extentionItem);
          }
          else if (item.Data[el].Type == "time") {
            displayLabel += item.Data[el].Value + extentionItem
          }
          else if (item.Data[el].Type == "content") {
            displayLabel += item.Data[el].ValueSort + extentionItem
          }
          if ((item.Data[el].Type == "image" || item.Data[el].Type == "croppedimage")) {
            displayLabel += item.Data[el].Value.Name + extentionItem
          }
          if (item.Data[el].Type == "datepicker" && item.Data[el].Value.length > 0) {
            displayLabel += this.changeTimeToReadebleDMY(item.Data[el].Value) + extentionItem
          }
        }
      });
      displayLabel += ", "
    }
    else {
      let keys = Object.keys(item.Data)

      for (var i = 0; i < keys.length; i++) {
        if (item.Data[keys[i]].Type == "text" || item.Data[keys[i]].Type == "richtext") {
          if (item.Data[keys[i]].Value[userLanguage] != undefined) {
            if (item.Data[keys[i]].Value[userLanguage] == "") {
              if (item.Data[keys[i]].Value[Object.keys(item.Data[keys[i]].Value)[0]] != "") {
                displayLabel += this.removeSpecialCharacters(item.Data[keys[i]].Value[Object.keys(item.Data[keys[i]].Value)[0]]) + ", "
              }
            } else if (item.Data[keys[i]].Value[userLanguage] != "") {
              displayLabel += this.removeSpecialCharacters(item.Data[keys[i]].Value[userLanguage]) + ", "
            }
          } else if (item.Data[keys[i]].Value[Object.keys(item.Data[keys[i]].Value)[0]] != "") {
            displayLabel += this.removeSpecialCharacters(item.Data[keys[i]].Value[Object.keys(item.Data[keys[i]].Value)[0]]) + ", "
          }
        }
      }
      if (displayLabel == "") {
        for (var ii = 0; i < keys.length; ii++) {
          if (item.Data[keys[ii]].Type == "datepicker" && item.Data[keys[ii]].Value.length > 0) {
            displayLabel += this.removeSpecialCharacters(item.Data[keys[ii]].Value) + ", "
          }
        }
      }
      if (displayLabel == "") {
        for (var iii = 0; iii < keys.length; iii++) {
          if ((item.Data[keys[iii]].Type == "image" || item.Data[keys[iii]].Type == "croppedimage") && item.Data[keys[iii]].Value.Alt[userLanguage].length > 0) {
            displayLabel += this.removeSpecialCharacters(item.Data[keys[iii]].Value.Alt[userLanguage]) + ", "
          }
        }
      }
      if (displayLabel == "") {

        for (var iiii = 0; iiii < keys.length; iiii++) {
          if ((item.Data[keys[iiii]].Type == "time")) {
            displayLabel += item.Data[keys[iiii]].Value + ", "
          }
        }
      }
    }

    displayLabel = displayLabel.replace(/(?:\r\n|\r|\n)/g, ' ');
    displayLabel = displayLabel.substr(0, displayLabel.length - 2)

    if (displayLabel.length > 75) {
      displayLabel = displayLabel.substring(0, 75 - 3) + "..."
    }

    return this.stripHtml(displayLabel)
  },
  isTypeTextOrRichText: function (param, userLanguage) {
    let displayLabel = ""
    if (param.Type == "text" || param.Type == "richtext") {
      if (param.Value[userLanguage] != undefined) {
        if (param.Value[userLanguage] == "") {
          if (param.Value[Object.keys(param.Value)[0]] != "") {
            displayLabel += this.removeSpecialCharacters(param.Value[Object.keys(param.Value)[0]])
          }
        } else if (param.Value[userLanguage] != "") {
          displayLabel += this.removeSpecialCharacters(param.Value[userLanguage])
        }
      } else if (param.Value[Object.keys(param.Value)[0]] != "") {
        displayLabel += this.removeSpecialCharacters(param.Value[Object.keys(param.Value)[0]])
      }
    }
    return displayLabel
  },
  isTypeNumber: function (param) {
    let displayLabel = ""
    displayLabel += param.Value
    if (param['IsPercentage']) {
      displayLabel += " %"
    }
    displayLabel += "  "
    return displayLabel
  },
  stripHtml: function (html) {
    var tmp = document.createElement("div");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  },
  removeSpecialCharacters: function (text) {
    text = this.stripHtml(text)
    return text.replace(/[^\w\s]/gi, '')
  },
  validateFormPageElements: function (formId) {
    var textInputsValid = this.validateElement(formId, "textInput")
    var richTextInputsValid = this.validateElement(formId, "quillWrapper")

    return textInputsValid && richTextInputsValid
  },
  validateElement: function (formId, elementClass) {
    var formTextElements = document.getElementById(formId).getElementsByClassName(elementClass)

    var errors = 0;

    for (var i = 0; i < formTextElements.length; i++) {
      var required = false;

      if (formTextElements[i].getAttribute("required") != null) {
        required = formTextElements[i].getAttribute("required").length > 0;
      }

      if (formTextElements[i].value == undefined) {
        var qlEditor = formTextElements[i].getElementsByClassName("ql-editor")[0]
        var classBefore = qlEditor.className

        if (qlEditor.innerText.length <= 1 && required) {
          qlEditor.className += " error"

          errors += 1;
        } else {
          qlEditor.className = classBefore.replace("error", "")
        }
      } else {
        if (formTextElements[i].value.length <= 0 && required) {
          formTextElements[i].className += " error"

          errors += 1;
        } else {
          formTextElements[i].className = elementClass
        }
      }
    }

    return errors == 0
  },
  pageItemDefinitionEqualToContentDefinition(itemData, contentDefinition) {
    let errors = []

    let itemKeys = Object.keys(itemData)
    let contentDefinitionKeys = Object.keys(contentDefinition)

    //check if object should be added
    for (let i = 0; i < contentDefinitionKeys.length; i++) {
      if (!(Object.prototype.hasOwnProperty.call(itemData, contentDefinitionKeys[i]))) {
        errors[errors.length] = "'" + contentDefinition[contentDefinitionKeys[i]].Title + Vue.i18n.translate("' is missing")
      } else if (itemData[contentDefinitionKeys[i]].Type != contentDefinition[contentDefinitionKeys[i]].Type) {
        //check if object types are equal
        errors[errors.length] = "'" + contentDefinitionKeys[i].Title + Vue.i18n.translate("' type is not equal to ") + contentDefinition[contentDefinitionKeys[i]].Type
      } else if (itemData[contentDefinitionKeys[i]].Type == "dropdown") {
        let itemOptions = itemData[contentDefinitionKeys[i]].Options
        let contentDefinitionOptions = contentDefinition[contentDefinitionKeys[i]].Options

        //check if options are not valid in dropdown
        for (let o = 0; o < itemOptions.length; o++) {
          let optionValueExists = false

          for (let oo = 0; oo < contentDefinitionOptions.length; oo++) {
            if (itemOptions[o].Value == contentDefinitionOptions[oo].Value) {
              optionValueExists = true
            }
          }

          if (!optionValueExists) {
            errors[errors.length] = Vue.i18n.translate("option '") + itemOptions[o].Value + Vue.i18n.translate("' should be removed")
          }
        }

        //check if options should be added in dropdown
        for (let o = 0; o < contentDefinitionOptions.length; o++) {
          let optionValueExists = false

          for (let oo = 0; oo < itemOptions.length; oo++) {
            if (contentDefinitionOptions[o].Value == itemOptions[oo].Value) {
              optionValueExists = true
            }
          }

          if (!optionValueExists) {
            errors[errors.length] = Vue.i18n.translate("option '") + contentDefinitionOptions[o].Value + Vue.i18n.translate("' should be added")
          }
        }
      }
    }

    //check if object should be removed
    for (let i = 0; i < itemKeys.length; i++) {
      if (!(Object.prototype.hasOwnProperty.call(contentDefinition, itemKeys[i]))) {
        errors[errors.length] = "'" + itemData[itemKeys[i]].Title + Vue.i18n.translate("' should be removed")
      }
    }

    if (errors.length == 0) {
      return true
    }

    return errors
  },
  createEmptyValueBasedOnType(type) {
    let value = ""

    if (type == "boolean") {
      value = false
    } else if (type == "text" || type == "richtext") {
      value = {}

      for (var i = 0; i < Vue.prototype.$config.contentLanguages.length; i++) {
        value[Vue.prototype.$config.contentLanguages[i]] = ""
      }
    } else if (type == "image" || type == "croppedimage") {
      value = {
        "Name": "",
        "Alt": {
          "Nl": "",
          "Fr": "",
          "En": ""
        }
      }
    } else if (type == "gallery") {
      value = [
        {
          "Name": "",
          "Alt": {
            "Nl": "",
            "Fr": "",
            "En": ""
          }
        }
      ]
    }

    return value
  },
  firstCharOfStringUpperCase(inputString) {
    return inputString.substr(0, 1).toUpperCase() + inputString.substr(1, inputString.length - 1).toLowerCase()
  },
  sortByKey(key) {
    return function (a, b) {
      return (a.Name[key].localeCompare(b.Name[key]))
    }
  },
  changeTimeToReadebleDMY(time) {
    let date = new Date(time),
      month = date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1,
      day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate(),
      year = date.getFullYear(),
      newDate = day + "/" + month + "/" + year
    return newDate;
  },
  changeTimeToReadeble(time, needsHM = true) {
    let date = new Date(time),
      month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1,
      day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate(),
      year = date.getFullYear(),
      houre = date.getHours() < 10 ? "0" + date.getHours() : date.getHours(),
      min =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes(),
      newDate = day + "-" + month + "-" + year + " - " + houre + ":" + min;
    if (!needsHM) {
      return day + "-" + month + "-" + year;
    }
    return newDate;
  },
  getBCPLangCode(currentLanguage) {
    let lang;
    switch (currentLanguage) {
      case "Nl":
        lang = "nl-BE";
        break;
      case "Fr":
        lang = "fr-FR";
        break;
      case "En":
        lang = "en-GB";
        break;
    }

    return lang;
  },
  getConfig() {
    return Vue.prototype.$config
  },
  getServerUrl() {
    return window.location.href.indexOf('localhost:') >= 0 ? Vue.prototype.$config.apiUrl : '';
  }
}
