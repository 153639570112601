<template>
  <div>
    <backButton />

    <template v-if="website">
      <div
        v-html="
          $t('Are you sure you want to delete module', {
            website: website.Name,
          })
        "
      ></div>
      <br />

      <cancelConfirmButtons
        @goBack="goBack"
        :confirmFunction="deleteWebsite"
        :loading="deleting"
        :confirmLabel="$t('Delete')"
      />
    </template>
    <template v-else-if="error.length == 0">
      <preloader></preloader>
    </template>

    <v-alert
      v-if="error.length > 0"
      class="mt-2"
      color="error"
      icon="warning"
      :value="true"
    >
      {{ error }}
    </v-alert>
  </div>
</template>

<script>
import request from "../../request";

export default {
  data() {
    return {
      error: "",
      website: null,
      deleting: false,
    };
  },
  created() {
    this.getWebsite(this.$route.params.id);
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    deleteWebsite() {
      this.deleting = true;

      request.delete("/website/" + this.$route.params.id, null, (res) => {
        this.deleting = false;

        if (res.success) {
          this.getWebsites();
          this.$router.go(-1);
        } else {
          this.error = res.message;
        }
      });
    },
    getWebsite(id) {
      if (id) {
        request.get("/website/" + id, null, (res) => {
          if (res.success) {
            this.website = res.data;
          } else {
            this.error = res.message;
          }
        });
      }
    },
    getWebsites() {
      request.get("/websites", null, (res) => {
        if (res.success) {
          this.$store.dispatch("setAllWebsites", res.data);
        } else {
          this.error = res.message;
        }
      });
    },
  },
};
</script>

<style>
</style>
