<template>
  <v-card class="px-8 py-4">
    <v-row>
      <v-col cols="12" sm="6" class="py-4 px-4 d-flex">
        <v-layout align-center class="text-sm-left" v-if="logo">
          <img id="logoLogin" class="" :src="serverUrl + '/images/' + logo" />
        </v-layout>
        <v-layout align-center class="text-sm-left" v-else>
          <img
            id="logoLogin"
            :src="serverUrl + '/images/' + 'logo-pos.svg' + '?v=' + getDate()"
            onerror="this.onerror=null;this.src=serverUrl +'/images/' + 'logo-pos.png'+ '?v=' + getDate();"
          />
        </v-layout>
      </v-col>
      <v-col cols="12" sm="6" class="pl-4">
        <languageSelector :addContainer="true" />
        <v-form @submit.prevent="login" ref="form" lazy-validation>
          <v-text-field
            :label="$t('Email')"
            v-model="email"
            required
            :rules="[
              (v) => !!v || $t('Email is required'),
              (v) =>
                /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                $t('Email must be valid'),
            ]"
            validate-on-blur
          ></v-text-field>

          <v-text-field
            :label="$t('Password')"
            v-model="pass"
            type="password"
            required
            :rules="[
              (v) => !!v || $t('Password is required'),
              (v) => (v && v.length > 4) || $t('Password is too short'),
            ]"
            validate-on-blur
          ></v-text-field>

          <div class="text-sm-right">
            <v-btn
              type="submit"
              :loading="loading"
              class="ml-0 mr-0"
              color="primary"
              >{{ $t("Login") }}</v-btn
            >
          </div>

          <v-alert
            class="mt-3"
            id="login-error"
            v-if="error.length > 0"
            color="error"
            icon="warning"
            :value="true"
          >
            {{ error }}
          </v-alert>
        </v-form>

        <p class="text-sm-right mt-3">
          <router-link to="/forgotpassword">
            {{ $t("Forgot password?") }}
          </router-link>
        </p>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import auth from "../../auth";
import helpers from "../../helpers";
import request from "../../request";

export default {
  data() {
    return {
      email: "",
      pass: "",
      error: "",
      loading: false,
      logo: this.$config.logoPos,
      serverUrl: helpers.getServerUrl(),
    };
  },

  methods: {
    login() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        auth.login(this.email, this.pass, (res) => {
          request.get("/websites", null, (websitesResponse) => {
            this.loading = false;
            if (!res.success) {
              this.error = res.message;
              this.pass = "";
            } else {
              this.$store.dispatch("setAllWebsites", websitesResponse.data);
              this.$i18n.set(res.data.Language);

              if (
                websitesResponse.data[0].DashboardPageId.$oid !==
                "000000000000000000000000"
              ) {
                this.$router.push({
                  name: "editPage",
                  params: { id: websitesResponse.data[0].DashboardPageId.$oid },
                });
                this.$router.go(1);
              } else {
                this.$router.push({ name: "dashboard" });
                this.$router.go(1);
              }
            }
          });
        });
      }
    },
    getDate() {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();

      return mm + dd + yyyy;
    },
  },
};
</script>

<style>
#login-container {
  max-width: 750px;
}

#logoLogin {
  max-width: 100%;
  width: 90%;
  margin: auto;
}
</style>
