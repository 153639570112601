var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"9","lg":"11"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"1"}},[_c('v-select',{staticClass:"pageLanguageSelector pr-3",attrs:{"items":_vm.pageLanguages,"single-line":""},model:{value:(_vm.pageDefaultLanguage),callback:function ($$v) {_vm.pageDefaultLanguage=$$v},expression:"pageDefaultLanguage"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"11"}},_vm._l((_vm.page.Name),function(translatedValue,translatedValueIndex){return _c('div',{key:translatedValueIndex},[(_vm.pageDefaultLanguage == translatedValueIndex)?_c('v-text-field',{attrs:{"label":_vm.$t('Name'),"single-line":"","required":"","rules":[
                function (v) { return !!v || _vm.$t('Name is required'); },
                function (v) { return v.length >= 2 ||
                  _vm.$t('Minimum required characters is') + ' 2'; } ],"validate-on-blur":""},model:{value:(_vm.page.Name[translatedValueIndex]),callback:function ($$v) {_vm.$set(_vm.page.Name, translatedValueIndex, $$v)},expression:"page.Name[translatedValueIndex]"}}):_vm._e()],1)}),0)],1)],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-layout',{staticClass:"mt-1",attrs:{"align-center":"","justify-end":"","fill-height":""}},[_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.copyItem()}}},[_c('v-icon',[_vm._v("mdi-plus-box-multiple")])],1),_c('router-link',{attrs:{"to":{
            name: 'editPageDetails',
            params: {
              name: _vm.page.Name[_vm.currentLanguage],
              websiteId: _vm.websiteId,
              id: _vm.page._id.$oid,
            },
          }}},[_c('v-btn',{attrs:{"icon":"","color":"orange"}},[_c('v-icon',[_vm._v("edit")])],1)],1),_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.removePage()}}},[_c('v-icon',[_vm._v("delete")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }