<template>
  <div>
    <backButton />

    <template v-if="user">
      <div
        v-html="
          $t('Are you sure you want to delete user', {
            user: user.FirstName + ' ' + user.LastName,
          })
        "
      ></div>
      <br />

      <cancelConfirmButtons
        @goBack="goBack"
        :confirmFunction="deleteUser"
        :loading="deleting"
        :confirmLabel="$t('Delete')"
      />
    </template>
    <template v-else-if="error.length == 0">
      <preloader></preloader>
    </template>

    <v-alert v-if="error.length > 0" color="error" icon="warning" :value="true">
      {{ error }}
    </v-alert>
  </div>
</template>

<script>
import request from "../../request";

export default {
  data() {
    return {
      error: "",
      user: null,
      deleting: false,
    };
  },
  created() {
    this.getUser(this.$route.params.id);
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    deleteUser() {
      this.deleting = true;

      request.delete("/user/" + this.$route.params.id, null, (res) => {
        this.deleting = false;

        if (res.success) {
          this.$router.go(-1);
        } else {
          this.error = res.message;
        }
      });
    },
    getUser(id) {
      if (id) {
        request.get("/user/" + id, null, (res) => {
          if (res.success) {
            this.user = res.data;
          } else {
            this.error = res.message;
          }
        });
      }
    },
  },
};
</script>

<style>
</style>
