<template>
  <v-card class="px-8 py-4">
    <languageSelector :addContainer="true" />

    <br />

    <template v-if="email.length > 0">
      <p v-html="$t('Activate account enter your', { email: email })" />

      <v-form @submit.prevent="activateAccount" ref="form" lazy-validation>
        <v-text-field
          :label="$t('Password')"
          v-model="pass"
          type="password"
          required
          validate-on-blur
          :rules="[
            (v) => !!v || $t('Password is required'),
            (v) => (v && v.length > 4) || $t('Password is too short'),
          ]"
        ></v-text-field>

        <v-text-field
          :label="$t('Repeat password')"
          v-model="repeatPass"
          type="password"
          required
          validate-on-blur
          :rules="[
            (v) => !!v || $t('Password is required'),
            (v) => (v && v.length > 4) || $t('Password is too short'),
          ]"
        ></v-text-field>

        <div class="text-sm-right">
          <v-btn
            class="ml-0 mr-0"
            type="submit"
            :loading="loading"
            color="primary"
            >{{ $t("Activate account") }}</v-btn
          >
        </div>
      </v-form>
    </template>
    <template v-else-if="error.length == 0">
      <preloader></preloader>
    </template>

    <v-alert
      class="mt-3"
      v-if="error.length > 0"
      color="error"
      icon="warning"
      :value="true"
    >
      {{ error }}
    </v-alert>

    <v-alert
      type="success"
      v-if="success.length > 0"
      class="mt-3"
      icon="check"
      :value="true"
    >
      {{ success }}
    </v-alert>
  </v-card>
</template>

<script>
import request from "../../request";

export default {
  data() {
    return {
      error: "",
      success: "",
      email: "",
      pass: "",
      repeatPass: "",
      loading: false,
    };
  },
  created() {
    this.getActivateAccountEmail();
  },
  methods: {
    getActivateAccountEmail() {
      this.error = "";
      this.success = "";

      request.get(
        "/activateaccountemail/" + this.$route.params.hash,
        null,
        (res) => {
          if (res.success) {
            this.email = res.data;
          } else {
            this.error = res.message;
          }
        }
      );
    },
    activateAccount() {
      this.error = "";
      this.success = "";

      if (this.$refs.form.validate()) {
        this.loading = true;

        request.post(
          "/activateaccount/" + this.$route.params.hash,
          {
            email: this.email,
            password: this.pass,
            passwordrepeat: this.repeatPass,
          },
          (res) => {
            this.loading = false;

            if (res.success) {
              this.success = res.message;

              setTimeout(() => {
                this.$router.push({ name: "login" });
                this.$router.go(1);
              }, this.$config.hideMessageTimeout);
            } else {
              this.error = res.message;
            }
          }
        );
      }
    },
  },
};
</script>

<style>
</style>
