<template>
  <div>
    <backButton />
    <h3 class="mb-4">{{ $t("Settings") }}</h3>
    <v-row no-gutters>
      <v-col cols="6" class="pr-4">
        <v-card class="mb-4">
          <v-card-title>
            {{ $t("Add light background logo") }}
          </v-card-title>
          <v-card-actions>
            <v-row no-gutters>
              <v-col cols="12">
                <v-card
                  elevation="0"
                  outlined
                  @drop.prevent="onDrop($event, 'dragover', 'imagePos')"
                  @dragover.prevent="dragover = true"
                  @dragenter.prevent="dragover = true"
                  @dragleave.prevent="dragover = false"
                >
                  <v-card-text>
                    <v-row
                      v-if="!imagePos.src"
                      class="d-flex flex-column cursor-pointer"
                      dense
                      align="center"
                      justify="center"
                      @click="$refs['file'].click()"
                    >
                      <v-icon
                        :class="[dragover ? 'mt-5, mb-3' : 'mt-5']"
                        size="60"
                      >
                        mdi-cloud-upload
                      </v-icon>
                      <p>
                        {{ $t("Drop your file here, or click to select one.") }}
                      </p>
                    </v-row>
                    <v-row v-if="imagePos.src">
                      <v-col cols="11">
                        <v-img
                          contain
                          max-height="350"
                          max-width="700"
                          :src="imagePos.src"
                        ></v-img>
                      </v-col>
                      <v-col cols="1">
                        <v-btn icon @click="imagePos.src = null">
                          <v-icon color="error">mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <input
                  ref="file"
                  type="file"
                  accept=".svg, .png"
                  style="display: none"
                  label="upload file"
                  @change="loadImage($event, 'imagePos')"
                />
              </v-col>

              <v-col v-if="config.logoPos" cols="12" class="mt-4">
                <v-alert dense type="warning" class="mb-0">{{
                  $t("Not available due to old config file")
                }}</v-alert>
              </v-col>
              <v-col cols="12" class="mt-4 text-right">
                <v-btn
                  @click="uploadCropedLogoLightBackground()"
                  :disabled="disablePos"
                  class="mb-2"
                >
                  {{ "Upload logo" }}</v-btn
                >
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="mb-4">
          <v-card-title>
            {{ $t("Add dark background logo") }}
          </v-card-title>
          <v-card-actions>
            <v-row no-gutters>
              <v-col cols="12">
                <v-card
                  elevation="0"
                  outlined
                  @drop.prevent="onDrop($event, 'dragover2', 'imageNeg')"
                  @dragover.prevent="dragover2 = true"
                  @dragenter.prevent="dragover2 = true"
                  @dragleave.prevent="dragover2 = false"
                >
                  <v-card-text>
                    <v-row
                      class="d-flex flex-column cursor-pointer"
                      dense
                      align="center"
                      justify="center"
                      @click="$refs['file2'].click()"
                      v-if="!imageNeg.src"
                    >
                      <v-icon
                        :class="[dragover2 ? 'mt-5, mb-3' : 'mt-5']"
                        size="60"
                      >
                        mdi-cloud-upload
                      </v-icon>
                      <p>
                        {{ $t("Drop your file here, or click to select one.") }}
                      </p>
                    </v-row>
                    <v-row v-if="imageNeg.src">
                      <v-col cols="11">
                        <v-img
                          contain
                          max-height="350"
                          max-width="700"
                          :src="imageNeg.src"
                        ></v-img>
                      </v-col>
                      <v-col cols="1">
                        <v-btn icon @click="imageNeg.src = null">
                          <v-icon color="error">mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <input
                  ref="file2"
                  type="file"
                  accept=".svg, .png"
                  style="display: none"
                  label="upload file"
                  @change="loadImage($event, 'imageNeg')"
                />
              </v-col>
              <v-col v-if="config.logoNeg" cols="12" class="mt-4">
                <v-alert dense type="warning" class="mb-0">{{
                  $t("Not available due to old config file")
                }}</v-alert>
              </v-col>
              <v-col cols="12" class="mt-4 text-right">
                <v-btn
                  @click="uploadCropedLogoDarkBackground()"
                  :disabled="disableNeg"
                  class="mb-2"
                >
                  {{ "Upload logo" }}</v-btn
                >
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-card-title>
            {{ $t("Add favicon") }}
          </v-card-title>
          <v-card-actions>
            <v-row no-gutters>
              <v-col cols="12">
                <v-card
                  elevation="0"
                  outlined
                  @drop.prevent="onDrop($event, 'dragover3', 'favicon')"
                  @dragover.prevent="dragover2 = true"
                  @dragenter.prevent="dragover2 = true"
                  @dragleave.prevent="dragover2 = false"
                >
                  <v-card-text>
                    <v-row
                      class="d-flex flex-column cursor-pointer"
                      dense
                      align="center"
                      justify="center"
                      @click="$refs['file3'].click()"
                      v-if="!favicon.src"
                    >
                      <v-icon
                        :class="[dragover2 ? 'mt-5, mb-3' : 'mt-5']"
                        size="60"
                      >
                        mdi-cloud-upload
                      </v-icon>
                      <p>
                        {{ $t("Drop your file here, or click to select one.") }}
                      </p>
                    </v-row>
                    <v-row v-if="favicon.src">
                      <v-col cols="11">
                        <v-img
                          contain
                          max-height="350"
                          max-width="700"
                          :src="favicon.src"
                        ></v-img>
                      </v-col>
                      <v-col cols="1">
                        <v-btn icon @click="favicon.src = null">
                          <v-icon color="error">mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <input
                  ref="file3"
                  type="file"
                  accept=".svg, .png"
                  style="display: none"
                  label="upload file"
                  @change="loadImage($event, 'favicon')"
                />
              </v-col>
              <v-col cols="12" class="mt-4 text-right">
                <v-btn @click="uploadCropedLogoFavicon()" class="mb-2">
                  {{ "Upload favicon" }}</v-btn
                >
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> {{ $t("Close") }} </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import "cropperjs/dist/cropper.css";

import request from "../../request";
export default {
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      snackbarColor: "success",
      dragover: null,
      dragover2: null,
      dragover3: null,
      files: null,
      imagePos: {
        src: null,
        file: null,
      },
      imageNeg: {
        src: null,
        file: null,
      },
      favicon: {
        src: null,
        file: null,
      },
      config: this.$config,
    };
  },
  computed: {
    disablePos() {
      if (this.$config.logoPos || !this.imagePos.src) {
        return true;
      }
      return false;
    },
    disableNeg() {
      if (this.$config.logoNeg || !this.imageNeg.src) {
        return true;
      }
      return false;
    },
  },
  methods: {
    loadImage(event, name) {
      // Reference to the DOM input element
      this[name].file = event.target.files[0];
      const file = event.target.files[0];
      // Ensure that you have a file before attempting to read it
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this[name].src = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },

    reset(imageName) {
      this[imageName] = {
        src: null,
        file: null,
      };
    },
    onDrop(e, refName, imageName) {
      this[imageName].src = null;
      this[refName] = false;
      // Reference to the DOM input element
      this[imageName].file = e.dataTransfer.files[0];
      const file = e.dataTransfer.files[0];
      // Ensure that you have a file before attempting to read it
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this[imageName].src = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    uploadCropedLogoLightBackground() {
      let formData = new FormData();
      formData.append("file", this.imagePos.file);
      request.post("/settings/upload-logo-pos", formData, (res) => {
        if (res.success) {
          this.reset("imagePos");
          this.snackbar = true;
           this.snackbarColor = "success";
          this.snackbarText = this.$t("Image successfully uploaded");
        } else {
          this.snackbar = true;
          if (res.title) {
            this.snackbarColor = "red";
            this.snackbarText = res.title;
          } else {
            this.snackbarColor = "red";

            this.snackbarText = this.$t(
              "Something went wrong while uploading, please try again."
            );
          }
        }
      });
    },
    uploadCropedLogoDarkBackground() {
      let formData = new FormData();
      formData.append("file", this.imageNeg.file);
      request.post("/settings/upload-logo-neg", formData, (res) => {
        if (res.success) {
          this.reset("imageNeg");
          this.snackbar = true;
          this.snackbarText = this.$t("Image successfully uploaded");
        } else {
          this.snackbar = true;
          if (res.title) {
            this.snackbarColor = "red";
            this.snackbarText = res.title;
          } else {
            this.snackbarColor = "red";

            this.snackbarText = this.$t(
              "Something went wrong while uploading, please try again."
            );
          }
        }
      });
    },
    uploadCropedLogoFavicon() {
      let formData = new FormData();
      formData.append("file", this.favicon.file);
      request.post("/settings/upload-favicon", formData, (res) => {
        if (res.success) {
          this.reset("favicon");
          this.snackbar = true;
          this.snackbarText = this.$t("Image successfully uploaded");
        } else {
          this.snackbar = true;
          if (res.title) {
            this.snackbarColor = "red";
            this.snackbarText = res.title;
          } else {
            this.snackbarColor = "red";

            this.snackbarText = this.$t(
              "Something went wrong while uploading, please try again."
            );
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>