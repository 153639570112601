<template>
  <div>
    <v-form ref="form" lazy-validation>
      <v-layout row wrap>
        <v-flex xs12 md2>
          <v-text-field
            :label="$t('Object name')"
            v-model="newObjectName"
            required
            :rules="[
              (v) => !!v || $t('Object name is required'),
              (v) =>
                v.length >= 2 || $t('Minimum required characters is') + ' 2',
            ]"
            validate-on-blur
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md2>
          <v-text-field
            :label="$t('Object title')"
            v-model="newObjectTitle"
            required
            :rules="[
              (v) => !!v || $t('Title name is required'),
              (v) =>
                v.length >= 2 || $t('Minimum required characters is') + ' 2',
            ]"
            validate-on-blur
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md2>
          <v-select
            v-model="newObjectType"
            v-bind:items="objectTypes"
            :label="$t('Select object type')"
            menu-props="bottom"
            required
            :rules="[(v) => !!v || $t('You must select an item')]"
            validate-on-blur
            class="mr-3"
          ></v-select>
        </v-flex>
        <v-flex xs12 md3>
          <v-btn
            v-if="enableAdd"
            class="
              mt-3
              dropdown-action-button
              edit-page-action-button
              add-page-element-btn
            "
            color="green"
            dark
            v-on:click="addItem()"
          >
            {{ $t("Add item") }}
            <v-icon>add</v-icon>
          </v-btn>
          <v-btn
            v-if="enableRemove"
            class="mt-3"
            color="red"
            dark
            v-on:click="removeItem()"
          >
            <v-icon>remove</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
export default {
  props: ["enableAdd", "enableRemove"],
  data() {
    return {
      newObjectType: null,
      newObjectName: "",
      newObjectTitle: "",
      objectTypes: this.$config.objectTypes,
      newObjectLanguages: true,
    };
  },
  methods: {
    addItem() {
      if (this.$refs.form.validate()) {
        this.$emit("addItem", {
          objectName: this.newObjectName,
          objectType: this.newObjectType,
          objectTitle: this.newObjectTitle,
          objectLanguages: this.newObjectLanguages,
        });
      }
    },
    removeItem() {
      this.$emit("removeItem", {
        objectName: this.newObjectName,
        objectType: this.newObjectType,
        objectTitle: this.newObjectTitle,
        objectLanguages: this.newObjectLanguages,
      });
    },
  },
};
</script>

<style>
@media only screen and (max-width: 960px) {
  .add-page-element-btn {
    margin: 0;
  }
}
</style>
