<template>
  <v-card class="px-8 py-4">
    <v-row>
      <v-col cols="12" sm="6" lg="5" class="py-4 px-4">
        <v-layout align-center class="text-sm-left" fill-height>
          <img
            id="logoLogin"
            v-if="logo"
            class=""
            :src="serverUrl + '/images/' + logo"
          />
          <img
            v-else
            id="logoLogin"
            :src="serverUrl + '/images/' + 'logo-pos.svg' + '?v=' + getDate()"
            onerror="this.onerror=null;this.src=serverUrl +'/images/' + 'logo-pos.png'+ '?v=' + getDate();"
          />
        </v-layout>
      </v-col>
      <v-col cols="12" sm="6" lg="7" class="py-4 px-4">
        <languageSelector :addContainer="true" />

        <br />

        <v-form @submit.prevent="sendResetLink" ref="form" lazy-validation>
          <v-text-field
            :label="$t('Email')"
            v-model="email"
            required
            validate-on-blur
            :rules="[
              (v) => !!v || $t('Email is required'),
              (v) =>
                /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                $t('Email must be valid'),
            ]"
          ></v-text-field>
          <v-row no-gutters class="mt-6">
            <v-col cols="6">
              <v-btn class="ml-0 mr-0" @click="$router.go(-1)">
                <v-icon left> mdi-arrow-left </v-icon>{{ $t("Cancel") }}</v-btn
              >
            </v-col>
            <v-col cols="6" class="text-right">
              <v-btn
                class="ml-0 mr-0"
                type="submit"
                :loading="loading"
                color="primary"
                >{{ $t("Send reset link") }}</v-btn
              >
            </v-col>
          </v-row>

          <v-alert
            class="mt-3"
            v-if="error.length > 0"
            color="error"
            icon="warning"
            :value="true"
          >
            {{ error }}
          </v-alert>

          <v-alert
            type="success"
            v-if="success.length > 0"
            class="mt-3"
            icon="check"
            :value="true"
          >
            {{ success }}
          </v-alert>
        </v-form>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import request from "../../request";
import helpers from "../../helpers";

export default {
  data() {
    return {
      logo: this.$config.logoPos,

      serverUrl: helpers.getServerUrl(),
      error: "",
      success: "",
      email: "",
      loading: false,
    };
  },
  methods: {
    sendResetLink() {
      this.error = "";
      this.success = "";

      if (this.$refs.form.validate()) {
        this.loading = true;

        request.post("/sendresetlink", this.email, (res) => {
          this.loading = false;

          if (res.success) {
            this.success = res.message;
          } else {
            this.error = res.message;
          }
        });
      }
    },
    getDate() {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();

      return mm + dd + yyyy;
    },
  },
};
</script>

<style>
</style>
