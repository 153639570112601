<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="time"
    :nudge-right="40"
    :disabled="timeOptions.timeDisabled"
    
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="time"
        :label="lableName"
        prepend-icon="mdi-clock-time-four-outline"
        :disabled="timeOptions.timeDisabled"
        v-bind="attrs"
        v-on="on"
        :rules="getRules"
        :clearable="timeOptions.timeClearable"
        @click:clear="$emit('clearTime')"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="menu && !timeOptions.showSeconds"
      v-model="time"
      full-width
      locale="nl-be"
      format="24hr"
      scrollable
      @click:minute="$refs.menu.save(time)"
    ></v-time-picker>
    <v-time-picker
      v-if="menu && timeOptions.showSeconds"
      v-model="time"
      full-width
      locale="nl-be"
      format="24hr"
      scrollable
      :use-seconds="timeOptions.showSeconds"
      @click:second="$refs.menu.save(time)"
    ></v-time-picker>
  </v-menu>
</template>

<script>
export default {
  props: ["lableName", "timeOptions", "changeTime", "timeValue"],
  data() {
    return {
      menu: false,
      time: this.timeValue,
    };
  },
  watch: {
    time: function (time) {
      this.$emit("changedTime", time);
    },
  },
  computed:{
    getRules() {
      if (!this.timeOptions.dataFieldValidation) {
        return [];
      }
      return this.timeOptions.dateRules;
    },
  }
};
</script>

<style>
</style>