<template>
  <div class="mt-5 text-right">
    <v-btn class="ml-0 mr-3 cancel-button" v-on:click="$emit('goBack')">{{ $t('Cancel') }}</v-btn>
    <v-btn color="primary save-button" v-on:click="confirmFunction" :loading="loading">{{ confirmLabel }}</v-btn>
  </div>
</template>

<script>
export default {
  props: ["confirmFunction", "confirmLabel", "loading"],
}
</script>

<style>
</style>
